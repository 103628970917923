<template>
  <v-col v-if="!showFilterSelect && !showSortSelect">
    <v-row>
      <v-card flat :style="$vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'">
        <v-data-table
            :headers="headers"
            :items="influencers"
            item-key="account"
            :items-per-page="options.itemsPerPage"
            :options.sync="options"
            :server-items-length="totalInfluencers"
            :loading="tableLoading"
            :footer-props="{
              itemsPerPageOptions: []
            }"
            disable-sort
            mobile-breakpoint="0"
            :hide-default-header="$vuetify.breakpoint.smAndDown"
            hide-default-footer
            style="backgroud-color: transparent">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field ref="keywordRef" :value="keyword" @keyup.enter="changeQuery" rounded hide-details :placeholder="searchOption === 'keyword' ? '상위 노출을 원하는 키워드를 검색해보세요.' : '블로거 아이디를 검색해보세요.'">
                <div class="mt-1" slot="prepend" style="width: 140px">
                  <v-select v-model="searchOption"
                            :items="[{text: '키워드', value: 'keyword'}, {text: '아이디', value: 'id'}]"
                            item-text="text"
                            item-value="value"
                            prepend-icon="search" dense solo flat hide-details></v-select>
                </div>
                <v-btn slot="append" class="white-two--text" color="#BD1328" @click="changeQuery" elevation="3">
                  검색
                </v-btn>
              </v-text-field>
            </v-toolbar>
            <div v-if="$route.query.keyword && keywords.length > 0" style="max-height: 320px; overflow-y: scroll;">
              <div style="background-color: #F2F2F2;width:100%;height:16px"></div>
              <div v-for="(item, index) in keywords" :key="index">
                <v-toolbar flat :height="$vuetify.breakpoint.mdAndUp ? '72px' : '120px'" :style="index === keywordIdx ? 'background-color: #F8FCFF' : 'color: #fff'">
                  <v-col @click="(keywordIdx!==index ? filterKeyword=false : ''); keywordIdx=index; updateKeywordHistory(index);" style="cursor:pointer">
                    <v-row align="center" :style="$vuetify.breakpoint.mdAndUp ? 'height: 56px' : 'height: 48px'">
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '5'" class="subheading font-weight-bold" :class="$vuetify.breakpoint.mdAndUp ? 'mt-2' : ''" :style="index === keywordIdx ? 'color: #4770BD' : 'color: #484848'" style="vertical-align:middle;">
                        {{item.name}}
                      </v-col>
                      <v-col class="hidden-md-and-up" cols="7">
                        <v-btn v-if="index === keywordIdx" rounded depressed @click="filterKeyword=true" color="#F5F5F5" style="color:#757575;font-size:13px">노출중인 블로거만 보기</v-btn>
                      </v-col>
<!--                      <v-col>-->
<!--                        <v-row style="color:#808080;font-size:10px">-->
<!--                          카테고리-->
<!--                        </v-row>-->
<!--                        <v-row class="caption" style="color:#808080">-->
<!--                          {{item.cat1 + ' > ' + item.cat2}}-->
<!--                        </v-row>-->
<!--                      </v-col>-->
                      <v-col cols="2" class="hidden-sm-and-down">
                        <v-row style="color:#808080;font-size:10px">
                          월간 검색량
                        </v-row>
                        <v-row class="caption" style="color:#808080">
                          {{item.mmqccnt | numFormat}}
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="hidden-sm-and-down">
                        <v-row style="color:#808080;font-size:10px">
                          상위노출 난이도
                        </v-row>
                        <v-row class="caption" style="color:#808080">
                          <v-chip v-if="parseInt(item.difficulty) <= 50000" color="#3DB5F8" text-color="white" small>매우 쉬움</v-chip>
                          <v-chip v-else-if="parseInt(item.difficulty) <= 100000" color="#3DD6C3" text-color="white" small>쉬움</v-chip>
                          <v-chip v-else-if="parseInt(item.difficulty) <= 200000" color="#81CF5D" text-color="white" small>보통</v-chip>
                          <v-chip v-else-if="parseInt(item.difficulty) <= 300000" color="#F0CB30" text-color="white" small>어려움</v-chip>
                          <v-chip v-else color="#FFA620" text-color="white" small>매우 어려움</v-chip>
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="hidden-sm-and-down">
                        <v-row style="color:#808080;font-size:10px">
                          상위노출 평균 유지 기간
                        </v-row>
                        <v-row>
                          <v-row class="caption" style="color:#808080">
                            {{item.duration | numFormat}}일
                          </v-row>
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="hidden-sm-and-down">
                        <v-row style="color:#808080;font-size:10px">
                          적정 광고단가
                        </v-row>
                        <v-row v-if="item.difficulty < 50000" class="caption" style="color:#808080">
                          {{ 30000 | numFormat}}~{{parseInt(item.difficulty / 50000) * 50000 + 50000 | numFormat}}원
                        </v-row>
                        <v-row v-else class="caption" style="color:#808080">
                          {{ parseInt(item.difficulty / 50000) * 50000 | numFormat}}~{{parseInt(item.difficulty / 50000) * 50000 + 50000 | numFormat}}원
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="hidden-sm-and-down">
                        <v-btn v-if="index === keywordIdx" rounded depressed @click="filterKeyword=!filterKeyword" :color="filterKeyword ? 'rouge' : '#F5F5F5'" :style="filterKeyword ? 'color:white;font-size:13px' : 'color:#757575;font-size:13px'">노출중인 블로거만 보기</v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="hidden-md-and-up mt-2">
                      <v-col>
                        <v-row style="color:#808080;font-size:10px">
                          월간 검색량
                        </v-row>
                        <v-row class="caption" style="color:#808080">
                          {{item.mmqccnt | numFormat}}
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row style="color:#808080;font-size:10px">
                          상위노출 난이도
                        </v-row>
                        <v-row class="caption" style="color:#808080">
                          <v-chip v-if="parseInt(item.difficulty) <= 50000" color="#3DB5F8" text-color="white" x-small>매우 쉬움</v-chip>
                          <v-chip v-else-if="parseInt(item.difficulty) <= 100000" color="#3DD6C3" text-color="white" x-small>쉬움</v-chip>
                          <v-chip v-else-if="parseInt(item.difficulty) <= 200000" color="#81CF5D" text-color="white" x-small>보통</v-chip>
                          <v-chip v-else-if="parseInt(item.difficulty) <= 300000" color="#F0CB30" text-color="white" x-small>어려움</v-chip>
                          <v-chip v-else color="#FFA620" text-color="white" x-small>매우 어려움</v-chip>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row style="color:#808080;font-size:10px">
                          상위노출 평균 유지 기간
                        </v-row>
                        <v-row class="caption" style="color:#808080">
                          {{item.duration | numFormat}}일
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row style="color:#808080;font-size:10px">
                          적정 광고단가
                        </v-row>
                        <v-row v-if="item.difficulty < 50000" class="caption" style="color:#808080">
                          {{ 30000 | numFormat}}~{{parseInt(item.difficulty / 50000) * 50000 + 50000 | numFormat}}원
                        </v-row>
                        <v-row v-else class="caption" style="color:#808080">
                          {{ parseInt(item.difficulty / 50000) * 50000 | numFormat}}~{{parseInt(item.difficulty / 50000) * 50000 + 50000 | numFormat}}원
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-toolbar>
              </div>
            </div>
            <div v-else-if="$route.query.keyword && keywords.length === 0 && searchOption === 'keyword'" class="pt-4">
              <v-row justify="center" style="color: #484848">
<!--                <v-col style="text-align:center">-->
<!--                  검색하신 '{{keyword}}' 키워드는 등록이 필요합니다.<br>-->
<!--                  등록이 필요한 키워드를 <a href="https://pf.kakao.com/_Jgyxid" style="text-decoration:underline;color:#4770BD" target="_blank">카카오톡 채널</a>로 말씀해주세요.-->
<!--                </v-col>-->
                <v-col style="text-align:center">
                  검색하신 <span style="color: #4770BD">'{{keyword}}'</span> 키워드로 유의미한 검색량이 없어 결과가 표시되지 않습니다.
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-2 pb-2">
<!--                <v-btn color="rouge" class="ma-0 ml-4 white-two&#45;&#45;text" tile flat style="font-size:13px" href="mailto:company@supermembers.co.kr?subject=키워드 추가 요청">-->
<!--                  키워드 추가 요청-->
<!--                </v-btn>-->
              </v-row>
            </div>
            <div style="background-color: #F2F2F2;width:100%;height:16px"></div>
            <v-toolbar flat>
              <div class="body-2 mr-4" style="color: #808080"> <!-- v-if="$vuetify.breakpoint.mdAndUp" -->
                <span class="subheading mr-4 font-weight-bold" style="color: #484848"><span style="color: #4770BD">{{keywords.length > 0 ? (keywords[keywordIdx].name) : ''}}</span>{{keywords.length > 0 ? ' 키워드 ' : ''}} 블로거 추천 </span>
                <span v-if="$vuetify.breakpoint.mdAndUp">검색 결과 </span>{{totalInfluencers}}명 ・ {{parseInt(totalInfluencers/options.itemsPerPage)}} Pages
              </div>
            </v-toolbar>
            <v-divider></v-divider>
            <v-expansion-panels v-model="optionPanelIdx" flat readonly>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="pl-3" style="display: block">
                  <v-row v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col>
                      <v-chip v-for="(filter, idx) in filters" :key="idx"
                              :class="filterIdx === idx ? 'white-two--text' : ''"
                              :color="(filterIdx === idx || (filter.sel && filter.sel.length > 0 && filter.sel.indexOf(0) === -1)) ? 'rouge' : '#F5F5F5'"
                              @click="filterExpand(idx)"
                              :disabled="idx === EXPECTEDVIEWCNTIDX && keywords.length === 0"
                              :outlined="(filter.sel && filter.sel.length > 0 && filter.sel.indexOf(0) === -1) && filterIdx !== idx"
                              style="color:#484848;font-size:13px">
                        {{ (filter.sel.length > 0 && filter.sel.indexOf(0) === -1) ? filter.name + ' ' + filter.sel.length + '개' : filter.name }}
                        <v-icon small>{{filter.icon}}</v-icon>
                      </v-chip>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px" @click="filterExpand(-1)">
                            {{options.itemsPerPage}}명씩 보기
                            <v-icon small>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, index) in ['20', '50', '100']" :key="index" @click="options.itemsPerPage = item">
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px" @click="filterExpand(-1)">
                            {{orderBy[orderIdx].name}}
                            <v-icon small>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, index) in orderBy" :key="index" @click="orderIdx=index">
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-chip @click="favorite=!favorite; filterExpand(-1)"
                              :class="favorite ? 'white-two--text' : ''"
                              :color="favorite ? 'rouge' : '#F5F5F5'" style="color:#484848;font-size:13px">
                        즐겨찾기
                      </v-chip>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="rouge" class="ma-0 white-two--text" tile flat @click="searchButtonPressed">
                        필터 적용
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-chip @click="filterExpand(-2)">
                      필터
                      <v-icon small>mdi-tune</v-icon>
                    </v-chip>
                    <v-chip @click="filterExpand(-1)">
                      정렬
                      <v-icon small>mdi-sort-variant</v-icon>
                    </v-chip>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-chip-group v-model="filters[filterIdx].sel" column multiple active-class="active white--text">
                      <v-chip v-for="(option, index) in filters[filterIdx].options" :key="index"
                              :outlined = "filters[filterIdx].sel.indexOf(index) === -1"
                              :color="filters[filterIdx].sel.indexOf(index) > -1 ? 'rouge' : ''"
                              style="color:#484848;font-size:13px; pointer-events: auto">
                        {{ option.text }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider></v-divider>
          </template>
          <template v-slot:header.check="{}">
            <v-icon dense class="mb-1" :color="influencerColor()"
                    @click="addToCampaign()">mdi-check-circle</v-icon>
          </template>
<!--          <template v-slot:header.channel="{header}">-->
<!--            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>-->
<!--          </template>-->
          <template v-slot:header.account="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.visitor="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.expectedViewCnt="{header}">
            <span style="font-size:10px;font-weight:normal">
              {{header.text}}
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" color="#4770BD" style="margin-bottom:2px" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                슈퍼차트 회원의 블로그 지수를 분석한 결과입니다.
              </v-tooltip>
            </span>
          </template>
          <template v-slot:header.reference="{header}">
            <span style="font-size:10px;font-weight:normal">
              {{header.text}}
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" color="#4770BD" style="margin-bottom:2px" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                슈퍼차트 회원이 등록한 레퍼런스 후기입니다.
              </v-tooltip>
            </span>
          </template>
          <template v-slot:header.adFee="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.acceptAvg="{header}">
            <span style="font-size:10px;font-weight:normal">
              {{header.text}}
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" color="#4770BD" style="margin-bottom:2px" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                광고를 제안했을 때 수락하는 금액의 평균 값입니다. 평균 수락 금액 이상으로 제안하길 권장드립니다.
              </v-tooltip>
            </span>
          </template>
          <template v-slot:header.count="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.rating="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.favorite="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
<!--          <template v-slot:header.data-table-expand="{header}">-->
<!--            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>-->
<!--          </template>-->
          <template v-slot:item.check="{item}">
            <v-row>
              <v-icon :dense="$vuetify.breakpoint.mdAndUp" class="mb-1" :color="influencerColor(item)"
                      :disabled="item.status > 0"
                      @click="addToCampaign(item)">mdi-check-circle</v-icon>
              <!--        <v-simple-checkbox v-model="selected[item.index]" :disabled="item.status > 0"></v-simple-checkbox>-->
            </v-row>
          </template>
<!--          <template v-slot:item.channel="props">-->
<!--            <naverIcon v-if="props.item.channel === 'naver'"/>-->
<!--            <instaIcon v-if="props.item.channel === 'insta'"/>-->
<!--            <youtubeIcon v-if="props.item.channel === 'youtube'"/>-->
<!--          </template>-->
          <template v-slot:item.account="props">
            <v-row @click="influencerDetailDlg(props.item)" class="py-2 pt-3 row-pointer">
              <v-col cols="2">
                <div style="display: block; position: relative">
                  <v-avatar size="40" style="position: absolute">
                    <img v-if="props.item.photo" :src="props.item.photo"/>
                    <img v-else src="@/assets/img/matching-placeholder.jpg">
                  </v-avatar>
                  <v-icon v-if="checkProposition(null, props.item)" size="18" color="rouge" style="margin-left: 22px; margin-top: 22px">mdi-check-circle</v-icon>
                </div>
              </v-col>
              <v-col cols="9" class="ml-2">
                <v-row style="color:#4770BD; padding-bottom:4px; font-size:13px;">
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small color="#15C072" class="mr-1" v-bind="attrs" v-on="on" v-if="props.item.innaver">mdi-at</v-icon>
                    </template>
                    네이버 인플루언서
                  </v-tooltip>
                  {{props.item.account}}
                  <v-icon small color="#4770BD" class="ml-1">mdi-text-box-search-outline</v-icon>
                </v-row>
                <v-row v-if="filters[0].sel > 0">
                  <v-chip outlined x-small color="#34CD89"
                          class="ma-0 mr-1 mb-1">
                    {{filters[0].options[filters[0].sel].text + ' ' + Math.ceil(props.item.categories[filters[0].options[filters[0].sel].text]) + '%'}}
                  </v-chip>
                </v-row>
                <v-row v-else>
                  <v-chip outlined x-small color="#34CD89"
                          class="ma-0 mr-1 mb-1"
                          v-for="item in Object.keys(props.item.categories).sort(function (a, b) { return props.item.categories[b] - props.item.categories[a]; }).slice(0, 2)" :key="item">
                    {{item + ' ' + Math.round(props.item.categories[item]) + '%'}}
                  </v-chip>
                </v-row>
                <v-row class="hidden-md-and-up">
                  <v-chip v-if="parseInt(props.item.adFee)/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 2" color="#3DB5F8" text-color="white" class="mx-0" x-small>상위노출 가능성 매우 높음</v-chip>
                  <v-chip v-else-if="props.item.adFee/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 1.2" color="#3DD6C3" text-color="white" class="mx-0" x-small>상위노출 가능성 높음</v-chip>
                  <v-chip v-else-if="props.item.adFee/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 0.8" color="#81CF5D" text-color="white" class="mx-0" x-small>상위노출 가능성 보통</v-chip>
                  <v-chip v-else-if="props.item.adFee/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 0.5" color="#F0CB30" text-color="white" class="mx-0" x-small>상위노출 가능성 낮음</v-chip>
                  <v-chip v-else color="#FFA620" text-color="white" class="mx-0" x-small>상위노출 가능성 매우 낮음</v-chip>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up pb-2" @click="influencerDetailDlg(props.item)">
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  {{ {naver:'블로그 순위', youtube:'구독자', insta:'팔로워'}['naver']}}
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{parseInt(props.item.rank) | numFormat}}
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  예상 광고 단가
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{parseInt(props.item.adFee) | numFormat}}위
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  평균 수락 금액
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{parseInt(props.item.acceptAvg) | numFormat}}
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up">
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  수락/거절 내역
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{ ((props.item.count || {}).accept || 0) + '회 / ' + ((props.item.count || {}).refuse || 0) + '회' }}
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  수락률/평점
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{(props.item.acceptRate || '-') + '%'}}/<span style="color:#F0CB30">{{'★' + (props.item.rating || '-')}}</span>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
          <template v-slot:item.visitor="{item}">
            <v-row class="caption" style="color:#808080">
              {{parseInt(item.rank) | numFormat}}위
            </v-row>
            <v-row style="color:#808080;font-size:10px">
              {{ {naver:'블로그 순위', youtube:'구독자', insta:'팔로워'}['naver']}}
            </v-row>
          </template>
          <template v-slot:item.expectedViewCnt="{item}">
            <v-row class="caption" style="color:#808080">
              <v-chip v-if="keywords.length === 0" color="#C2C2C2" text-color="white" small>-</v-chip>
              <v-chip v-else-if="parseInt(item.adFee)/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 2" color="#3DB5F8" text-color="white" small>매우 높음</v-chip>
              <v-chip v-else-if="item.adFee/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 1.2" color="#3DD6C3" text-color="white" small>높음</v-chip>
              <v-chip v-else-if="item.adFee/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 0.8" color="#81CF5D" text-color="white" small>보통</v-chip>
              <v-chip v-else-if="item.adFee/(keywords.length > 0 ? keywords[keywordIdx].difficulty : 100000) >= 0.5" color="#F0CB30" text-color="white" small>낮음</v-chip>
              <v-chip v-else color="#FFA620" text-color="white" small>매우 낮음</v-chip>
            </v-row>
          </template>
          <template v-slot:item.reference="{item}">
            <div clas="caption" v-if="item.metadata">
              <v-menu v-model="item.previewMenu" :nudge-width="200" offset-y>
                <template v-slot:activator="scope">
                  <div target="_blank" style="color:#4770BD; font-size:13px" v-bind="scope.attrs" v-on="scope.on">
                    미리보기
                    <v-icon small color="#4770BD" class="mb-1">mdi-chevron-down</v-icon>
                  </div>
                </template>
                <v-card style="width:536px;background-color: #FFFFFF" class="pa-4 pt-3">
                  <div v-if="(item.reference || []).length > 0" class="caption mb-2" color="#484848">대표 게시물 미리보기</div>
                  <v-sheet v-if="(item.reference || []).length > 0" class="mx-auto" v-dragscroll style="height:228px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                    <a :href="((item.reference[index] || {}).link || '').replace('m.', '')" target="_blank" v-for="(metadata, index) in item.metadata" :key="index">
                      <div class="mx-1" style="maxWidth:120px">
                        <img v-if="metadata.image" width="120px" height="108px" :src="metadata.image" :alt="metadata.title"/>
                        <img v-else src="@/assets/img/matching-placeholder.jpg">
                        <div>
                          <v-chip outlined x-small color="#34CD89"
                                  class="ma-0 mr-1">
                            {{(item.reference[index] || {}).category}}
                          </v-chip>
                        </div>
                        <p v-html="metadata.title" class="caption" style="width:120px;color:#484848;white-space:normal;"></p>
                      </div>
                    </a>
                  </v-sheet>
                  <div v-if="(item.reviews || []).length > 0" class="caption my-2">슈퍼차트 캠페인 진행 사례</div>
                  <v-sheet v-if="(item.reviews || []).length > 0" class="mx-auto" v-dragscroll style="height:228px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                    <a :href="((item.reviews[index] || {}).url || '').replace('m.', '')" target="_blank" v-for="(review, index) in item.reviews" :key="index">
                      <div class="mx-1" style="maxWidth:120px">
                        <img v-if="review.image" width="120px" height="108px" :src="review.image" :alt="review.title"/>
                        <img v-else src="@/assets/img/matching-placeholder.jpg">
                        <p v-html="review.title" class="caption" style="width:120px;color:#484848;white-space:normal;"></p>
                      </div>
                    </a>
                  </v-sheet>
                </v-card>
              </v-menu>
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.adFee="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{parseInt(item.adFee) | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.acceptAvg="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{item.acceptAvg | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.count="{item}">
            <v-row class="caption" style="color:#808080">
              {{ ((item.count || {}).accept || 0) + '회 / ' + ((item.count || {}).refuse || 0) + '회' }}
            </v-row>
          </template>
          <template v-slot:item.rating="{item}">
            <v-row class="caption" style="color:#808080">
              {{(item.acceptRate || '-') + '%'}}/<span style="color:#F0CB30">{{'★' + (item.rating || '-')}}</span>
            </v-row>
          </template>
          <template v-slot:item.favorite="{item}">
            <v-icon :color="checkFavorite(item) ? 'pink' : ''" @click="setFavorite(item)">{{checkFavorite(item) ? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="2" class="hidden-sm-and-down">
            </td>
            <td :colspan="7">
              <v-row align="center">
                <v-col cols="2" style="font-size:10px;color:#808080">
                  제안 금액
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon size="14" color="#4770BD" style="margin-bottom:2px" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    예상 광고단가의 80-200% 금액으로 제안하실 수 있습니다.
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model.number="item.price" type="number"
                                @input="item.price = Math.round(Math.abs($event))"
                                @keypress="isNumber($event)"
                                outlined dense hide-details style="font-size:13px">
<!--                                :disabled="!editable[item.account]">-->
<!--                    <v-icon slot="prepend-inner"-->
<!--                            @click="editable[item.account] = !editable[item.account]" class="edit-button">-->
<!--                      edit-->
<!--                    </v-icon>-->
                  </v-text-field>
                </v-col>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-col cols="3">
                  <v-menu v-model="item.campaignAddMenu" :close-on-content-click="false" :nudge-width="200" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="rouge" class="ma-0 ml-4 white-two--text" tile flat v-bind="attrs" v-on="on" style="font-size:13px" @click="checkPrice(item)">
                        <v-icon small class="mr-2">add</v-icon>
                        제안 목록에 추가
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader style="color:#484848;font-size:13px">다음 캠페인에 추가</v-subheader>
                        <v-list-item v-if="naverCampaigns.length === 0">
                          <v-list-item-title style="font-size:14px">
                            <router-link to="/regcam/" style="color:#484848"><v-icon>mdi-plus-circle-outline</v-icon> 새 캠페인 추가</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="(campaign, index) in naverCampaigns" @click="updateToProposition(campaign, item)" :key="index">
                          <v-list-item-title style="font-size:14px" :style="checkProposition(campaign, item) === 2 ? 'color:#c2c2c2' : 'color:#484848'">{{ campaign.name }}
                            <v-chip outlined small color="#757575" v-if="checkProposition(campaign, item) === 2">제안 중</v-chip>
                            <v-chip outlined small color="#BD1328" v-else-if="checkProposition(campaign, item)">삭제</v-chip>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
              v-model="options.page"
              :length="Math.floor(totalInfluencers / options.itemsPerPage + 1)"
              :total-visible="10"
          ></v-pagination>
        </div>
      </v-card>
    </v-row>
    <v-expand-transition>
      <v-card v-if="bottomNav" class="transition-fast-in-fast-out overlay" style="height: 160px;z-index:1">
        <v-divider color="#BD1328"></v-divider>
        <v-divider color="#BD1328"></v-divider>
        <v-row class="pa-4 pb-0" style="background-color: white">
          <v-col md="6" style="font-size:13px;color: #484848;">
            <v-row>
              제안 캠페인
            </v-row>
            <v-row class="mt-2" v-if="naverCampaigns.length > 0">
              <v-select :items="naverCampaigns"
                        item-text="name"
                        item-value="id"
                        :value="((naverCampaigns || [])[selectedCampaign] || {}).id"
                        height="40px"
                        hide-details outlined solo flat single-line
                        @change="changeCampaign">
              </v-select>
            </v-row>
            <v-row v-else>
              <v-btn tile outlined color="rouge" to="/regcam/">+ 새 캠페인 추가</v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions class="px-4 py-2">
          <span><b style="font-size: 14px; color: #BD1328">{{_.compact(selected).length}}명</b>의 인원을 캠페인 제안 목록에 추가합니다.</span>
          <v-spacer></v-spacer>
          <v-btn flat tile color="rouge white--text" @click="updateAllToProposition()" :disabled="_.compact(selected).length === 0" width="160" height="40">
            제안 목록에 추가
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
    <v-row justify="center" align="center">
      <v-dialog v-model="showLocalFilterDialog" content-class="white-two" max-width="500px">
        <v-card class="pa-4">
          <v-card-title class="pa-2">
            <div>
              <v-icon color="default" @click="showLocalFilterDialog=false">close</v-icon>
              <br>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="font-weight-bold ml-6">
              활동 지역 선택
            </v-row>
            <v-row class="ml-6">
              <v-chip @click="filters[LOCALIDX].sel=[]">
                지역 전체
              </v-chip>
            </v-row>
            <v-expansion-panels flat>
              <v-expansion-panel v-for="(subRegion, region) in locals" :key="region" style="background-color: white">
                <v-expansion-panel-header>
                  {{region}}{{'(' + (_.intersection(_.concat(_.keys(locals[region]), [region]), filters[LOCALIDX].sel).length || '') + ')'}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip @click="setLocalSels(region, null)"
                          :class="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'white-two--text' : ''"
                          :color="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'rouge' : '#F5F5F5'">
                    전체
                  </v-chip>
                  <v-chip v-for="(value, key) in subRegion" :key="key"
                          @click="setLocalSels(region, key)"
                          :class="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'white-two--text' : ''"
                          :color="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'rouge' : '#F5F5F5'"
                          style="color:#484848;font-size:13px">
                    {{value}}
                  </v-chip>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
<!--            <div v-for="(subRegion, region) in locals" :key="region">-->
<!--              <v-row class="mt-4">-->
<!--                {{region}}-->
<!--              </v-row>-->
<!--              <v-chip v-for="(value, key) in locals[region]" :key="key"-->
<!--                      @click="localSels.indexOf(key) > -1 ? (localSels = localSels.filter((sel) => sel !== key)) : localSels.push(key)"-->
<!--                      :class="localSels.indexOf(key) > -1 ? 'white-two&#45;&#45;text' : ''"-->
<!--                      :color="localSels.indexOf(key) > -1 ? 'rouge' : '#F5F5F5'"-->
<!--                      style="color:#484848;font-size:13px">-->
<!--                {{value}}-->
<!--              </v-chip>-->
<!--            </div>-->
          </v-card-text>
<!--          <v-card-actions>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn tile outlined color="rouge" @click="showLocalFilterDialog=false">취소</v-btn>-->
<!--            <v-btn class="rouge white-two&#45;&#45;text" tile flat @click="showLocalFilterDialog=false">선택</v-btn>-->
<!--          </v-card-actions>-->
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showCompleteDialog" content-class="white-two" max-width="256px">
        <v-card class="pa-4">
          <v-card-subtitle style="font-size:13px;padding:0">
            {{completeDialogTitle}}
          </v-card-subtitle>
          <v-card-text>
            <div class="body-1" style="color:#484848;">{{completeDialogTxt1}}</div>
            <div class="" style="font-size:13px;color:#757575;">{{completeDialogTxt2}}</div>
          </v-card-text>
          <v-card-actions style="padding: 16px 0 0 0">
            <v-spacer></v-spacer>
            <v-btn color="rouge" tile flat outlined @click="$router.push({ name: 'Proposition', params: {initCampaign: selectedCampaign}})">목록으로</v-btn>
            <v-btn class="rouge white-two--text" tile flat @click="showCompleteDialog=false">확인</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- influencer detail -->
    <v-row justify="center">
      <InfluencerDetail v-model="openInfluencerDetail"
                        :openInfluencer="openInfluencer"
                        :checkProposition="checkProposition"
                        :updateToProposition="updateToProposition"
                        :checkFavorite="checkFavorite"
                        :setFavorite="setFavorite">
      </InfluencerDetail>
    </v-row>
  </v-col>
  <v-col v-else-if="showFilterSelect" style="margin-bottom: -24px">
    <v-toolbar flat>
      <div @click="showFilterSelect=false">
        < 필터 선택
      </div>
    </v-toolbar>
    <v-row v-for="(filter, idx) in filters" :key="idx">
      <v-col v-if="idx !== LOCALIDX && !(idx === EXPECTEDVIEWCNTIDX && keywords.length === 0)" style="background-color: white" class="pa-4 mt-2">
        <v-row>
          {{filter.name}}
        </v-row>
        <v-row>
          <v-chip-group v-model="filter.sel" column multiple active-class="active white--text">
            <v-chip v-for="(option, index) in filter.options" :key="index"
                    @click="expand=false"
                    :outlined = "filter.sel.indexOf(index) === -1"
                    :color="filter.sel.indexOf(index) > -1 ? 'rouge' : ''"
                    style="color:#484848;font-size:13px; pointer-events: auto">
              {{ option.text }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row style="background-color: white">
          활동 지역
        </v-row>
        <v-row style="background-color: white">
          <v-chip @click="filters[LOCALIDX].sel=[]">
            지역 전체
          </v-chip>
        </v-row>
        <v-expansion-panels flat>
          <v-expansion-panel v-for="(subRegion, region) in locals" :key="region" style="background-color: white">
            <v-expansion-panel-header>
              {{region}}{{'(' + (_.intersection(_.concat(_.keys(locals[region]), [region]), filters[LOCALIDX].sel).length || '') + ')'}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-chip @click="setLocalSels(region)"
                      :class="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'white-two--text' : ''"
                      :color="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'rouge' : '#F5F5F5'">
                전체
              </v-chip>
              <v-chip v-for="(value, key) in locals[region]" :key="key"
                      @click="setLocalSels(region, key)"
                      :class="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'white-two--text' : ''"
                      :color="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'rouge' : '#F5F5F5'"
                      style="color:#484848;font-size:13px">
                {{value}}
              </v-chip>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="py-4" style="background-color: white">
      <v-col cols="6">
        <v-btn color="#757575" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="() => {
          filters.forEach((filter) => {
            filter.sel = 0;
          });
        }">
          초기화
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="rouge" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="showFilterSelect=false, searchButtonPressed()">
          선택 적용
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
  <v-col v-else style="margin-bottom: -24px">
    <v-toolbar flat>
      <div @click="showSortSelect=false">
        < 정렬 기준
      </div>
    </v-toolbar>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row>
          즐겨찾기 항목만 보기
        </v-row>
        <v-row>
          <v-chip @click="favorite=!favorite"
                  :class="favorite ? 'white-two--text' : ''"
                  :color="favorite ? 'rouge' : '#F5F5F5'" style="color:#484848;font-size:13px">
            지금 적용
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row>
          정렬 순서
        </v-row>
        <v-row>
          <v-chip v-for="(item, index) in orderBy" :key="index"
                  @click="orderIdx=index"
                  :class="orderIdx === index ? 'white-two--text' : ''"
                  :outlined = "orderIdx !== index"
                  :color="orderIdx === index ? 'rouge' : ''"
                  style="color:#484848;font-size:13px">
            {{item.name}}
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row>
          한 페이지에 보이는 항목 수
        </v-row>
        <v-row>
          <v-chip v-for="(item, index) in ['20', '50', '100']" :key="index"
                  @click="options.itemsPerPage=item"
                  :class="options.itemsPerPage === item ? 'white-two--text' : ''"
                  :outlined = "options.itemsPerPage !== item"
                  :color="options.itemsPerPage === item ? 'rouge' : ''"
                  style="color:#484848;font-size:13px">
            {{item}}
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-4" style="background-color: white">
      <v-col cols="6">
        <v-btn color="#757575" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="options.itemsPerPage=20;orderIdx=0">
          초기화
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="rouge" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="showSortSelect=false, searchButtonPressed">
          선택 적용
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import campaignMixin from '@/components/shared/campaignMixin'
  import Vue from "vue";
  import InfluencerDetail from '@/components/campaign/dialog/InfluencerDetail'

  const age20 = Vue.moment().subtract(30, 'years').year();
  const age30 = Vue.moment().subtract(40, 'years').year();
  const age40 = Vue.moment().subtract(50, 'years').year();
  // const CATEGORYIDX = 0;
  // const GENDERIDX = 1;
  // const LOCALIDX = 2;
  // const AGEIDX = 3;
  // const EXPECTEDVIEWCNTIDX = 5;
  // const ADFEEIDX = 6;

  export default {
    name: "Search",
    mixins: [userMixin, campaignMixin],
    components: {
      // naverIcon: () => import('@/assets/img/campaign/naver.svg'),
      // instaIcon: () => import('@/assets/img/campaign/insta.svg'),
      // youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
      InfluencerDetail
    },
    data() {
      return {
        expanded: [],
        editable: {},
        // categories: [
        //   {name: '음식', icon: 'mdi-food', sub: ['식품', '음식점']},
        //   {name: '뷰티', icon: 'mdi-lipstick', sub: ['헤어/네일', '다이어트', '패션/잡화', '화장품']},
        //   {name: '문화', icon: 'mdi-movie-open', sub: ['도서/팬시', '엔터테인먼트']},
        //   {name: '정보', icon: 'mdi-lightbulb-on', sub: ['교육', 'IT/전자기기']},
        //   {name: '생활', icon: 'mdi-sofa-single', sub: ['생활/가구', '스포츠/레저', '웨딩', '출산/육아']},
        //   {name: '여행', icon: 'mdi-airballoon', sub: ['여행']},
        //   {name: '게임', icon: 'mdi-gamepad-square', sub: ['게임']},
        //   {name: '일상', icon: 'mdi-home-heart', sub: ['반려동물', '일상']},
        // ],
        orderBy: [
          {name: '추천순', sql: 'orderBy=DATE(lastLogin)&direction=desc'},
          {name: '랭킹 높은순', sql: 'orderBy=`rank`&direction=asc' },
          {name: '금액 높은순', sql: 'orderBy=adFee&direction=desc'},
          {name: '금액 낮은순', sql: 'orderBy=adFee&direction=asc'}
        ],
        CATEGORYIDX: 0,
        LOCALIDX: 2,
        EXPECTEDVIEWCNTIDX: 5,
        headers_desktop: [
          { text: '', value: 'check'},
          // { text: '채널', value: 'channel', width: '70px' },
          { text: '인플루언서 정보', value: 'account', width: '280px' },
          { text: '대표 샘플', value: 'reference' },
          { text: '인플루언서 지표', value: 'visitor' },
          { text: '상위노출 가능성', value: 'expectedViewCnt' },
          { text: '예상 광고 단가', value: 'adFee' },
          { text: '평균 수락 금액', value: 'acceptAvg' },
          { text: '수락/거절 내역', value: 'count' },
          { text: '수락률/평점', value: 'rating' },
          { text: '즐겨찾기', value: 'favorite' }
          // { text: '제안하기', value: 'data-table-expand', width: '78px' },
        ],
        headers_mobile: [
          { text: '', value: 'check', width: '40px'},
          { text: '인플루언서 정보', value: 'account' },
          { text: '제안하기', value: 'data-table-expand', width: '78px' }
        ],
        filters: [
          {name: '카테고리', icon: 'expand_more', options: [
              {text: '전체', query: '', sql: ''},
              {text: '건기식', query: 'categories[]=건기식', sql: 'categories like "%건기식%"'},  
              {text: '게임', query: 'categories[]=게임', sql: 'categories like "%게임%"'},
              {text: '교육', query: 'categories[]=교육', sql: 'categories like "%교육%"'},
              {text: '금융', query: 'categories[]=금융', sql: 'categories like "%금융%"'},
              {text: '다이어트', query: 'categories[]=다이어트', sql: 'categories like "%다이어트%"'},
              {text: '도서/팬시', query: 'categories[]=도서/팬시', sql: 'categories like "%도서/팬시%"'},
              {text: '레저', query: 'categories[]=레저', sql: 'categories like "%레저%"'},
              {text: '반려동물', query: 'categories[]=반려동물', sql: 'categories like "%반려동물%"'},
              {text: '부동산', query: 'categories[]=부동산', sql: 'categories like "%부동산%"'},
              {text: '생활/가구', query: 'categories[]=생활/가구', sql: 'categories like "%생활/가구%"'},
              {text: '스포츠', query: 'categories[]=스포츠', sql: 'categories like "%스포츠%"'},
              {text: '식품', query: 'categories[]=식품', sql: 'categories like "%식품%"'},
              {text: '엔터테인먼트', query: 'categories[]=엔터테인먼트', sql: 'categories like "%엔터테인먼트%"'},
              {text: '여행', query: 'categories[]=여행', sql: 'categories like "%여행%"'},
              {text: '웨딩', query: 'categories[]=웨딩', sql: 'categories like "%웨딩%"'},
              {text: '음식점', query: 'categories[]=음식점', sql: 'categories like "%음식점%"'},
              {text: '일상', query: 'categories[]=일상', sql: 'categories like "%일상%"'},
              {text: '정보', query: 'categories[]=정보', sql: 'categories like "%정보%"'},
              {text: '주식', query: 'categories[]=주식', sql: 'categories like "%주식%"'},
              {text: '출산/육아', query: 'categories[]=출산/육아', sql: 'categories like "%출산/육아%"'},
              {text: '패션/잡화', query: 'categories[]=패션/잡화', sql: 'categories like "%패션/잡화%"'},
              {text: '헤어/네일', query: 'categories[]=헤어/네일', sql: 'categories like "%헤어/네일%"'},
              {text: '화장품', query: 'categories[]=화장품', sql: 'categories like "%화장품%"'},
              {text: 'IT/전자기기', query: 'categories[]=IT/전자기기', sql: 'categories like "%IT/전자기기%"'}
            ], sel: [0]},
          // {name: '채널', icon: 'expand_more', options: [
          //     {text: '전체'},
          //     {text: '네이버 블로그', query: 'channel=naver'}
          //     // {text: '인스타그램', query: 'channel=insta'},
          //     // {text: '유튜브', query: 'channel=youtube'}
          //   ], sel: 0},
          {name: '성별', icon: 'expand_more', options: [
              {text: '전체', query: ''},
              {text: '남성', query: 'gender=남자'},
              {text: '여성', query: 'gender=여자'}
            ], sel: [0]},
          {name: '활동 지역', icon: 'expand_more', options: [
            ], sel: [0]},
          {name: '연령', icon: 'mdi-tune', options: [
              {text: '전체', sql: ''},
              {text: '20대', sql: 'birth > ' + age20},
              {text: '30대', sql: 'birth <= ' + age20 + ' AND birth > ' + age30},
              {text: '40대', sql: 'birth <= ' + age30 + ' AND birth > ' + age40},
              {text: '50대 이상', sql: 'birth <= ' + age40}
            ], sel: [0]},
          // {name: '슈퍼멤버스 순위', icon: 'mdi-tune', options: [
          //     {text: '전체', sql: ''},
          //     {text: '상위 5%', sql: 'percentage <= 5'},
          //     {text: '상위 5-20%', sql: 'percentage > 5 AND percentage <= 20'},
          //     {text: '상위 20-50%', sql: 'percentage > 20 AND percentage <= 50'},
          //     {text: '상위 50%-100%', sql: 'percentage > 50'},
          //     // {text: '직접 입력', query: ''}
          //   ], sel: 0},
          // {name: '팔로워', icon: 'mdi-tune', options: []},
          {name: '네이버 인플루언서', icon: 'mdi-tune', options: [
              {text: '전체', query: ''},
              {text: '인플루언서만 보기', query: 'innaver=1'},
              {text: '인플루언서 제외', query: 'innaver=0'},
            ], sel: [0]},
          {name: '상위노출 가능성', icon: 'mdi-tune', options: [
              {text: '전체', sql: ''},
              {text: '매우 높음', sql: ' '},
              {text: '높음', sql: ' '},
              {text: '보통', sql: ' '},
              {text: '낮음', sql: ' '},
              {text: '매우 낮음', sql: ' '},
            ], sel: [0]},
          {name: '단가', icon: 'mdi-tune', options: [
              {text: '전체', sql: ''},
              {text: '5만원 미만', sql: 'adFee < 50000'},
              {text: '5-10만원', sql: 'adFee >= 50000 AND adFee < 100000'},
              {text: '10-20만원', sql: 'adFee >= 100000 AND adFee < 200000'},
              {text: '20만원이상', sql: 'adFee >= 200000'}
            ], sel: [0]},
          {name: '제안 가능 여부', icon: 'expand_more', options: [
              {text: '전체', query: ''},
              {text: '제안 가능만 보기', sql: 'matchingSvc=2'}
            ], sel: [1]}
        ],
        keyword: '',
        oldFilterIdx: 0,
        filterIdx: 0,
        orderIdx: 0,
        influencers: [],
        totalInfluencers: 0,
        tableLoading: false,
        options: { page: 1, itemsPerPage: 20 },
        propositions: [],
        showCompleteDialog: false,
        completeDialogTitle: '',
        completeDialogTxt1: '',
        completeDialogTxt2: '',
        favorite: false,
        favorites: [],
        openInfluencer: {},
        openInfluencerDetail: false,
        showLocalFilterDialog: false,
        locals: {},
        keywords: [],
        keywordIdx: 0,
        showFilterSelect: false,
        showSortSelect: false,
        selected: [],
        bottomNav: true,
        selectedCampaign: 0,
        filterKeyword: false,
        searchOption: 'keyword',
        keywordHistory: null,
        optionPanelIdx: null
      }
    },
    computed: {
      headers() {
        return this.$vuetify.breakpoint.mdAndUp ? this.headers_desktop : this.headers_mobile;
      },
      filters_str() {
        return JSON.stringify(this.filters)
      }
    },
    watch: {
      options: {
        handler () {
          if ((this.$route.query.keyword || this.keyword || '').length > 0 && this.searchOption === 'id') {
            this.search(false);
          }
          else {
            this.selected = [];
            this.getDataFromApi();
          }
        },
        deep: true,
      },
      filters_str(newValue_str, oldValue_str) {
        let newValue = JSON.parse(newValue_str);
        let oldValue = JSON.parse(oldValue_str);
        newValue.map((filter, idx) => {
          if (!this._.isEqual(filter.sel, oldValue[idx].sel)) {
            let index = filter.sel.indexOf(0);
            if (filter.sel.length > 1 && index > -1) {
              if (index === 0)
                this.filters[idx].sel.splice(index, 1)
              else
                this.filters[idx].sel.shift();
            }
          }
        });
        this.options.page = 1;
        // this.getDataFromApi();
      },
      // filters: {
      //   handler (newValue, oldValue) {
      //     newValue.map((filter, idx) => {
      //       if (!this._.isEqual(filter.sel, oldValue[idx].sel)) {
      //         let index = newValue.indexOf(0);
      //         if (newValue.length > 1 && index > -1) {
      //           if (index === 0)
      //             newValue.splice(index, 1)
      //           else
      //             newValue.shift();
      //         }
      //       }
      //     });
      //
      //     this.options.page = 1;
      //     this.getDataFromApi();
      //   },
      //   deep: true,
      // },
      // localSels: {
      //   handler () {
      //     this.options.page = 1;
      //     this.getDataFromApi();
      //   },
      //   deep: true
      // },
      favorite: {
        handler () {
          this.getDataFromApi();
        }
      },
      orderIdx: {
        handler () {
          if ((this.$route.query.keyword || this.keyword || []).length > 0 && this.searchOption === 'id') {
            this.search(false);
          }
          else {
            // this.getDataFromApi();
          }
        },
        deep: true,
      },
      '$route.query.keyword': {
        async handler () {
          if ((this.$route.query.keyword || "").length > 0) {
            if (this.searchOption === 'keyword')
            {
              await this.getKeywords();
              await this.getDataFromApi();
            }
            else
            {
              this.search(true);
            }
          }
        },
        deep: true,
      },
      filterKeyword: {
        handler () {
          this.getDataFromApi();
        },
        deep: true
      },
      searchOption(value) {
        if (value === 'id') {
          this.keyword = '';
          this.keywords.splice(0);
          this.keywordIdx = 0;
        }
      }
    },
    methods: {
      filterExpand (idx) {
        if (idx === -1) {
          if (this.$vuetify.breakpoint.mdAndUp) {
            this.optionPanelIdx = 1;
            this.showSortSelect = false;
          }
          else {
            this.optionPanelIdx = 1;
            this.showSortSelect = true;
          }
        }
        else {
          if (idx >= 0 && idx === this.LOCALIDX) {
            this.optionPanelIdx = 1;
            this.showLocalFilterDialog = true;
          }
          else if (this.filterIdx === idx) {
            this.optionPanelIdx = 1 - this.optionPanelIdx;
          }
          else {
            if (this.$vuetify.breakpoint.mdAndUp) {
              this.optionPanelIdx = 0;
              this.showLocalFilterDialog = false;
            } else {
              this.optionPanelIdx = 0;
              this.showFilterSelect = true;
            }
          }
          if (idx >= 0) {
            this.filterIdx = idx;
          }
        }
      },
      checkProposition(campaign, influencer) {
        if (campaign) {
          if (this._.find(this.propositions, (proposition) => {
            return (proposition.campaignId === campaign.id
                && proposition.taggableId === influencer.id
                && proposition.taggableType === 'naver'
                && proposition.status === 1);
          })) {
            return 2
          }
          else {
            return this._.find(this.propositions, (proposition) => {
              return (proposition.campaignId === campaign.id
                  && proposition.taggableId === influencer.id
                  && proposition.taggableType === 'naver'
                  && proposition.status < 2);
            });
          }
        }
        else {
          return (this.naverCampaigns || []).some((campaign) => {
            return this._.find(this.propositions, (proposition) => {
              return (proposition.campaignId === campaign.id
                && proposition.taggableId === influencer.id
                && proposition.taggableType === 'naver'
                && proposition.status < 2);
            });
          })
        }
      },
      async updateToProposition(campaign, influencer) {
        if (campaign[influencer.channel] === undefined) {
          this.$store.commit('setMsg', `채널 정보가 없어 목록에 추가할 수 없는 캠페인입니다.`);
          this.$store.commit('setShowSnackbar', true);
          return;
        }
        this.completeDialogTxt1 = campaign.name;
        let oldProposition = this.checkProposition(campaign, influencer);
        if (!oldProposition) {
          let maxEditCount = this.$getMaxEditCount(influencer.price);
          let proposition = {
            campaignId: campaign.id,
            taggableId: influencer.id,
            taggableType: 'naver',
            uid: influencer.uid,
            point: influencer.price,
            cname: campaign.serviceName,
            creward: campaign[influencer.channel].reward,
            cprice: campaign[influencer.channel].rewardPrice,
            category: campaign.category,
            status: 0,
            maxEditCount: maxEditCount,
            thumbnail: campaign.thumbnail,
            periodUse: this.$periodMap[influencer.channel][campaign.type][campaign.category.firstName][0],
            periodFirstCopy: this.$periodMap[influencer.channel][campaign.type][campaign.category.firstName][1]
          }
          if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
            proposition.rewardFees = 13.5;
            proposition.rewardFeeAmount = campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
          }
          await this.axios.post('/campaign/proposition', proposition);
          await this.getPropositions();
          this.completeDialogTitle = '추가 완료';
          this.completeDialogTxt2 = '제안목록에 추가되었습니다.';
          this.showCompleteDialog = true;
        }
        else if (oldProposition !== 2) {
          await this.axios.delete('/campaign/proposition?id=' + oldProposition.id);
          await this.getPropositions();
          this.completeDialogTitle = '삭제 완료';
          this.completeDialogTxt2 = '제안목록에서 삭제되었습니다.';
          this.showCompleteDialog = true;
        }
      },
      async updateAllToProposition() {
        let campaign = this.naverCampaigns[this.selectedCampaign];
        if (campaign['naver'] === undefined) {
          this.$store.commit('setMsg', `채널 정보가 없어 목록에 추가할 수 없는 캠페인입니다.`);
          this.$store.commit('setShowSnackbar', true);
          return;
        }
        this.completeDialogTxt1 = campaign.name;
        await Promise.all(this.selected.map(async(select, idx) => {
          if (select) {
            let influencer = this.influencers[idx];
            let oldProposition = this.checkProposition(campaign, influencer);
            if (!oldProposition) {
              let maxEditCount = this.$getMaxEditCount(influencer.price);
              let proposition = {
                campaignId: campaign.id,
                taggableId: influencer.id,
                taggableType: 'naver',
                uid: influencer.uid,
                point: influencer.price,
                cname: campaign.serviceName,
                creward: campaign[influencer.channel].reward,
                cprice: campaign[influencer.channel].rewardPrice,
                category: campaign.category,
                status: 0,
                maxEditCount: maxEditCount,
                thumbnail: campaign.thumbnail,
                periodUse: this.$periodMap[influencer.channel][campaign.type][campaign.category.firstName][0],
                periodFirstCopy: this.$periodMap[influencer.channel][campaign.type][campaign.category.firstName][1]
              }
              if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
                proposition.rewardFees = 13.5;
                proposition.rewardFeeAmount = campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
              }
              return await this.axios.post('/campaign/proposition', proposition);
            }
          }
        }));
        await this.getPropositions();
        this.completeDialogTitle = '추가 완료';
        this.completeDialogTxt2 = '제안목록에 추가되었습니다.';
        this.showCompleteDialog = true;
      },
      changeQuery() {
        this.keyword = this.$refs.keywordRef.internalValue;
        this.keywordIdx = 0;
        this.$router.push({path: 'search', query: {searchOption: this.searchOption, keyword: this.keyword}});
      },
      async search(isFirst) {
        const { page, itemsPerPage} = this.options
        let pageQuery = '&page=' + (isFirst ? 1 : page) + '&limit=' + itemsPerPage;
        try {
          let influencers = await this.axios.get('/influencer?channel=naver&search=' + (this.$route.query.keyword || this.keyword) + '&' + this.orderBy[this.orderIdx].sql + pageQuery);
          this.totalInfluencers = influencers.data.total;
          this.influencers = influencers.data.influencers;

          this.influencers = this.influencers.map((influencer) => {
            // let mainCategories = {};
            // this._.toPairs(influencer.categories).map((category) => {
            //   this.categories.forEach((maincategory) => {
            //     if (maincategory.sub.indexOf(category[0]) > -1) {
            //       mainCategories[maincategory.name] = mainCategories[maincategory.name] || 0;
            //       mainCategories[maincategory.name] += category[1];
            //     }
            //   });
            // });
            // influencer.mainCategories = mainCategories;
            influencer.price = influencer.adFee;
            return influencer;
          });
        }
        catch (e) {
          console.log(e);
        }
      },
      async getKeywords() {
        this.filterKeyword = false;
        let keywords = await this.axios.get('/keyword/matching?name=' + this.keyword.replace(/\s/g,''));
        this.keywords = keywords.data.keywords;
        if (this.keywords.length === 0 || !this._.find(this.keywords, {name: this.keyword.replace(/\s/g,'')})) {
          keywords = await this.axios.post('/keyword/matching/request?name=' + this.keyword.replace(/\s/g,'') + '&email=' + this.user.email);
          this.keywords = keywords.data.keywords;
        }
        this.keywords = this.keywords.filter((keyword) => {
          return (keyword.mmqccnt >= 300 && keyword.difficulty > 0);
        });
        let index = this._.findIndex(this.keywords, (keyword) => {
          return keyword.name === this.keyword.replace(/\s/g,'');
        });

        if (index > -1) {
          this.keywords = this._.chain(this.keywords).partition({name: this.keyword.replace(/\s/g,'')}).flatten().value();
        }

        let result = await this.axios.post('/keyword/matching/history', {searchKeyword: this.keyword.replace(/\s/g,''), companyId: this.user.id});
        this.keywordHistory = result.data;
      },
      async updateKeywordHistory(index) {
        if (this.keywordHistory) {
          await this.axios.put('/keyword/matching/history', {
            selectKeyword: this.keywords[index].name,
            id: this.keywordHistory.id
          });
        }
      },
      async setFavorite(influencer) {
        if (this.checkFavorite(influencer)) {
          await this.axios.delete('/company/user/favorite?companyId=' + this.user.id +
            '&taggableId=' + influencer.id + '&taggableType=naver');
        }
        else {
          await this.axios.post('/company/user/favorite', {companyId: this.user.id, taggableId: influencer.id, taggableType: 'naver'});
        }

        let favorites = await this.axios.get('/company/user/favorite?companyId=' + this.user.id + '&taggableType=naver&inc=1');
        this.favorites = favorites.data.favorites;
      },
      checkFavorite(influencer) {
        return this._.find(this.favorites, (favorite) => {
          return (favorite.taggableId === influencer.id && favorite.taggableType === influencer.channel)
        });
      },
      async getDataFromApi() {
        // this.$router.push({ path: 'search'});
        if (this.favorite) {
          this.totalInfluencers = this.favorites.length;
          this.influencers = this.favorites.map(favorite => favorite.Influencer);
        }
        else {
          this.tableLoading = true;
          const { /*sortBy, sortDesc, */page, itemsPerPage} = this.options
          let pageQuery = '&page=' + page + '&limit=' + itemsPerPage;
          let query = '';
          let sql = 'query=';
          this.filters.forEach((filter, idx) => {
            if (idx === this.LOCALIDX && this.filters[this.LOCALIDX].sel.length > 0) {
              this.filters[this.LOCALIDX].sel.forEach((sel) => {
                if (this.locals[sel]) {
                  Object.keys(this.locals[sel]).map((key) => {
                    query += '&local[]=' + key;
                  });
                }
                else if(sel != 0)
                {
                  query += '&local[]=' + sel;
                }
              });
            }
            else {
              if (filter.sel.length > 0 && filter.sel.indexOf(0) === -1) {
                if (idx === this.EXPECTEDVIEWCNTIDX) {
                  filter.options = [
                    {text: '전체', sql: ''},
                    {text: '매우 높음', sql: 'adFee >= ' + (2 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000))},
                    {text: '높음', sql: 'adFee >= ' + (1.2 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000)) + ' AND adFee < ' + (2 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000))},
                    {text: '보통', sql: 'adFee >= ' + (0.8 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000)) + ' AND adFee < ' + (1.2 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000))},
                    {text: '낮음', sql: 'adFee >= ' + (0.5 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000)) + ' AND adFee < ' + (0.8 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000))},
                    {text: '매우 낮음', sql: 'adFee < ' + (0.5 * (this.keywords.length > 0 ? this.keywords[this.keywordIdx].difficulty : 100000))},
                  ];
                }

                let selSql = '';
                filter.sel.forEach((sel) => {
                  if (filter.options[sel].sql) {
                    if (idx === this.CATEGORYIDX) {
                      selSql += (selSql.length > 7 ? ' AND ' : '') + encodeURIComponent(filter.options[sel].sql);
                    }
                    else {
                      selSql += (selSql.length > 7 ? ' OR ' : '') + encodeURIComponent(filter.options[sel].sql);
                    }
                  }
                  if (filter.options[sel].query) {
                    query += '&' + filter.options[sel].query;
                  }
                });
                if (selSql.length > 0) {
                  sql += (sql.length > 7 ? ' AND ' : '') + '(' + selSql + ')';
                }
              }
              else {
                if (filter.sel > 0 && filter.options[filter.sel].query) {
                  query += '&' + filter.options[filter.sel].query;
                }
              }
            }
          });

          if (this.filterKeyword) {
            // sql += (sql.length > 7 ? ' AND ' : '') + 'keywords like "%' + encodeURIComponent(this.keyword) + '%"'
            query += '&keyword=' + this.keywords[this.keywordIdx].name.replace(/\s/g,'');
          }

          if (this.searchOption === 'id' && this.keyword) {
            query += ('&search=' + this.keyword);
          }

          let influencers = await this.axios.get('/influencer?' + this.orderBy[this.orderIdx].sql +
            pageQuery + query + (sql.length > 7 ? ('&' + sql) : ''));
          this.totalInfluencers = influencers.data.total;
          this.influencers = influencers.data.influencers;
          this.tableLoading = false;
        }

        this.influencers = this.influencers.map((influencer) => {
          // let mainCategories = {};
          // this._.toPairs(influencer.categories).map((category) => {
          //   this.categories.forEach((maincategory) => {
          //     if (maincategory.sub.indexOf(category[0]) > -1) {
          //       mainCategories[maincategory.name] = mainCategories[maincategory.name] || 0;
          //       mainCategories[maincategory.name] += category[1];
          //     }
          //   });
          // });
          // influencer.mainCategories = mainCategories;
          influencer.price = influencer.adFee;

          let propositions = this.propositions.filter((proposition) => (proposition.taggableId == influencer.id));
          influencer.count = this._.countBy(propositions, (proposition) => {
            if (proposition.status === 2 || proposition.status >= 10) {
              return 'accept';
            }
            else if (proposition.status === 3 || proposition.status === 4) {
              return 'refuse';
            }
            else if (proposition.status === 1) {
              return 'wait';
            }
            else {
              return 'list';
            }
          });
          return influencer;
        });
      },
      async influencerDetailDlg(item, event) {
        console.log(event)
        let preview = this.influencers.some((influencer) => influencer.previewMenu);
        if (preview)
          return;
        this.openInfluencerDetail = true;
        this.openInfluencer = item;
      },
      checkPrice(influencer) {
        if ((influencer.price || 0) < Math.round(influencer.adFee * this.$minProposeRate)) {
          this.$store.commit('setMsg', `해당 회원의 최소 제안가격은 ${Math.round(influencer.adFee * this.$minProposeRate)}원 입니다`);
          influencer.price = Math.round(influencer.adFee * this.$minProposeRate);
          this.$store.commit('setShowSnackbar', true);
        }
        else if(influencer.price > (influencer.adFee * this.$maxProposeRate)) {
          this.$store.commit('setMsg', `해당 회원의 최대 제안가격은 ${influencer.adFee * this.$maxProposeRate}원 입니다`);
          influencer.price = influencer.adFee * this.$maxProposeRate;
          this.$store.commit('setShowSnackbar', true);
        }
      },
      isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode < 48 || charCode > 57) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      influencerColor(item) {
        if (item) {
          let index = this.influencers.indexOf(item);
          if (index > -1) {
            return (item.matchingSvc !== 2 ? 'card-background' : ((this.selected[index]) ? 'rouge' : 'default-light'));
          }
          else {
            return '';
          }
        }
        else {
          return ((this.selected.filter(Boolean).length > 0) ? 'rouge' : 'default-light');
        }
      },
      addToCampaign(item) {
        if (item) {
          let index = this.influencers.indexOf(item);
          if (index > -1 && item.matchingSvc === 2) {
            this.selected[index] = !this.selected[index];
            this.$forceUpdate();
          }
        }
        else {
          let check = this.influencers.every((influencer, index) => {
            return this.selected[index];
          })
          this.influencers.map((influencer, index) => {
            this.selected[index] = !check;
          });
          this.$forceUpdate();
        }
        // this.bottomNav = this.selected.some((select) => select);
      },
      changeCampaign(id) {
        this.naverCampaigns.some((campaign, idx) => {
          if (campaign.id === id) {
            this.selectedCampaign = idx;
            return true;
          }
          return false
        });
      },
      async getPropositions() {
        let filterCampaign = '';
        if (this.naverCampaigns.length > 0) {
          this.naverCampaigns.map((campaign, idx) => {
            if (idx) {
              filterCampaign += ('&campaignId[]=' + campaign.id);
            } else {
              filterCampaign += ('?campaignId[]=' + campaign.id);
            }
          });
          let propositions = await this.axios.get('/campaign/proposition' + filterCampaign);
          this.propositions = propositions.data.propositions;
        }
      },
      setLocalSels(region, key) {
        if (key) {
          if (this.filters[this.LOCALIDX].sel.indexOf(region) > -1) {
            this.filters[this.LOCALIDX].sel = this.filters[this.LOCALIDX].sel.filter((sel) => sel !== region)
          }
          if(this.filters[this.LOCALIDX].sel.indexOf(key) > -1) {
            this.filters[this.LOCALIDX].sel = this.filters[this.LOCALIDX].sel.filter((sel) => sel !== key)
          } else {
            this.filters[this.LOCALIDX].sel.push(key)
          }
        }
        else {
          this.filters[this.LOCALIDX].sel = [region];
        }
      },
      async searchButtonPressed(){
        await this.getDataFromApi();
      }
    },
    async created() {
      this.keyword = this.$route.query.keyword;
      this.searchOption = this.$route.query.searchOption || 'keyword';
      if (this.keyword) {
        if (this.searchOption === 'keyword')
          await this.getKeywords();
      }
      await this.getPropositions();
      // await this.getDataFromApi();

      if (this.user) {
        let favorites = await this.axios.get('/company/user/favorite?companyId=' + this.user.id + '&taggableType=naver&inc=1');
        this.favorites = favorites.data.favorites;
      }

      let option = await this.axios.get('/company/option?type=local')
      this.locals = option.data;
      // this.locals.others = {};
      // this._.merge(Object.keys(this.locals).map((key) => {
      //   let obj = {};
      //   obj[this._.invert(this.locals[key])['전체']] = key;
      //   this.locals.others = this._.assign(this.locals.others, obj);
      // }));
      // delete this.locals.others[undefined];
    }
  }
</script>

<style scoped lang="scss">

  .edit-button {
    pointer-events: auto;
  }

  ::v-deep .v-expansion-panel__header {
    padding: 0px;
  }

  ::v-deep .v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: none;
  }

  ::v-deep tr.v-data-table__expanded.v-data-table__expanded__row > td {
    border-bottom: none !important;
  }

  ::v-deep tr.v-data-table__expanded.v-data-table__expanded__row > td {
    border-bottom: none !important;
  }

  .row-pointer:hover {
    cursor: pointer;
  }

  .overlay {
    position: fixed;
    bottom: 0px;
    width: 1120px;
    height: 160px;
    background-color: #ffffff
  }
</style>